import React, {useEffect, useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {API} from "aws-amplify";
import AnswerStatus from "./questionShowDone"
import * as mutations from "../graphql/mutations";
import {getAnswerbyQid, getQuestion, getQuestionChapter} from "../graphql/queries";



function ShowMyQuestions(props) {
    const [hotid, setHotid] = useState("load");
    const [hotchapter, setHotchapter] = useState("load");
    const [hotanswer, setHotanswer] = useState([" "]);
    const [hotnamq, setHotq] = useState("load");
    const [show, setShow] = useState(false);

    const [value, setValue] = useState(),
        onInput = ({target:{value}}) => setValue(value),
        onFormSubmit = async (e,id) => {
            e.preventDefault()
            setValue()


            const data = {
                id: hotid ,
                answer: value,
                status: "done",
            };



            const update = await API.graphql({ query: mutations.updateQuestionprivate, variables: {input: data}})
            console.log("updated response", update)
            handleClose();
            handleChange("myquestions");






        }






    const handleClose = () => setShow(false);




    async function handleShow(question,id,chapter,answer){


        console.log("taylor2", question)

        setShow(true)
        setHotid(id);
        setHotq(question)
        setHotchapter(chapter)
        setHotanswer(answer)







    }


    function handleChange(event) {
        // Here, we invoke the callback with the new value
        console.log(event)
        props.onChange(event)
    }




    const { myquestions } = props;

    console.log("hot", hotanswer)


    return (


        <ListGroup >


            <ListGroup.Item>
                {!!myquestions && myquestions.map((item, index) => (<ListGroup.Item  key={index}>
                    Question: {item.question}  <p> </p>
                    <p> </p> <Button variant="outline-primary"  size="sm" onClick={() => handleShow(item.question,item.id, item.chapter, item.answer)}>Explore
                </Button>
                    <Modal

                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Question and Answer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            Question: {hotnamq} <p> </p> Response: {hotanswer} <p> </p>

                            <Form onSubmit={e => onFormSubmit(e, item.id) }>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    onChange={onInput}
                                    value={value}
                                />

                                <Button type="submit">
                                    Save
                                </Button>
                            </Form>



                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>



                </ListGroup.Item>)) }</ListGroup.Item>
        </ListGroup>)
}














export default ShowMyQuestions
