import React, {useEffect, useState} from 'react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner, getOwnerContact, getOwnerSub} from "./graphql/queries";
import "./Style.css";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Navvy from "./components/nav"
import Card from "react-bootstrap/Card";
import {simpleParser} from "mailparser";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";


function Allmem() {




    const [subs, setSubs] = useState(["Loading.."]);
    const [subject, setSubjet] = useState("Click Email Below");
    const [body, setBody] = useState("");
    const [image, setImage] = useState(["test"]);
    const [from, setFrom] = useState("");
    const [files, setFiles] = useState(["Loading.."]);
    const [lists3, setLists3] = useState(["Loading.."]);
    const [files3, setFiles3] = useState(["Loading.."]);





    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();
            let ding = await Auth.currentCredentials();
            let test = await Auth.currentUserInfo();

           // console.log("user", user)
          //  console.log("testAuth", test)
         //   console.log("ding", ding)



           // loadlists3();




            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            const sub1 = await API.graphql({
                query:   getOwnerSub,
                variables: {

                    owner : user.username

                }
            })




            setSubs(sub1.data.getOwnerSub.items)




        })();

    }, [])



  async function doshit(item){


setFiles3(["clean"])

//console.log("Danger", item)

      Storage.get( item.key, {level: 'private', download: true})
          .then((data) => {
              var reader = new FileReader();
              reader.onload = function() {

                  simpleParser(reader.result, (err, parsed) => {
                      if (err) {
                          console.log(err, err.stack);

                      } else {
                       //      console.log("date:", parsed.date);

                       //     console.log("TAYLOR LOOKS:", parsed);

                          if (parsed.attachments === undefined || parsed.attachments.length == 0) {
                              setBody(parsed.text)
                              setSubjet(parsed.subject)
                              setFrom(parsed.from.value[0].name)


                          //    console.log("body:", body)

                              const file = {body:parsed.text, subject:parsed.subject, from: parsed.from.value[0].name, date: parsed.date.toString()};


                              setFiles3(Files3 => [...Files3, file])

                          } else {


                              function bufferToBase64(buf) {
                                  var binstr = Array.prototype.map.call(buf, function (ch) {
                                      return String.fromCharCode(ch);
                                  }).join('');
                                  return btoa(binstr);
                              }


                              const doubled = parsed.attachments.map(n =>  new Uint8Array(n.content));
                              const endit = doubled.map(n => bufferToBase64(n))

                              // console.log("endit", endit)

                              //console.log("FRIDAY LOOK", endit)

                              //    var data = new Uint8Array(parsed.attachments[0].content);

                              //   var base64 = bufferToBase64(data);

                              // console.log("TG LOOK:",parsed.attachments[0].content );
                              setBody(parsed.text)
                              setImage(endit)
                              setSubjet(parsed.subject)
                              setFrom(parsed.from.value[0].name)

                              // console.log("image", image);
                         //     console.log("from:", parsed.from.text);
                          //    console.log("body:", body)

                              const file = {body:parsed.text, subject:parsed.subject, from: parsed.from.value[0].name, image:endit, date:parsed.date.toString()};


                              setFiles3(Files3 => [...Files3, file])

                              //      console.log("attachments:", parsed.attachments);

                          }
                      }

                  });

              }
              reader.readAsText(data.Body);
          })
          .catch((err) => {
              console.log("error fetching image")
          })



  }

   /* async function loadlists3(){





        var test = await  Storage.list('Hunter', { level: 'private' })

       console.log("s3", test)

        //console.log("TG-key", test[0].key)

       // let  boom = await test.forEach(doshit);

      //  console.log(boom)



    }
*/

    async function buttonAll(){


        var test = await  Storage.list('', { level: 'private' })

    //    console.log("s3", test)
        let boom = await test.forEach(doshit);

    //    console.log(boom)



    }

    async function buttonKid(kid){


        var test = await  Storage.list(kid, { level: 'private' })

      //  console.log("s3", test)
        let boom = await test.forEach(doshit);

       // console.log(boom)



    }











    return (
        <div>
            <Navvy />

            <Alert key={1} variant={"success"}>
                While each browser is different, you should go to File and Print.  Once at the print page, you should get the option to save it as a PDF.
            </Alert>


            <Container fluid>
                <Container fluid="md">

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <ListGroup>
                                <ListGroup.Item><p>Children:  <Button onClick={() => buttonAll()}>Show All </Button></p> { subs.map((item, index) => (<ListGroup.Item key={index}>Name: {item.name} <p>Email: {item.id}@protect.memhold.com</p>

                               <Button onClick={() => buttonKid(item.name)} >Show {item.name}</Button> </ListGroup.Item>)) }</ListGroup.Item>
                            </ListGroup>





                        </Col>


                    </Row>
                </Container>

            </Container>




            <Container fluid>

                <Row>
                    <Col >


                            <>
                                {files3.map(({ subject, from,body, image, index, date }) => (
                                    <Card  >

                                        <Card.Body>

                                            <Card.Title>{from}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{subject} </Card.Subtitle>
                                            <Card.Text>
                                                {body}


                                                {image != undefined &&
                                                <Image key={index} src={"data:image/png;base64," + image } fluid  />
                                                }

                                                <h6>Date: {date} </h6>
                                            </Card.Text>

                                        </Card.Body>

                                    </Card>




                                ))}
                            </>



                    </Col>


                </Row>
            </Container>

        </div>
    );
}
export default Allmem ;


