import React, {useEffect, useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import {API} from "aws-amplify";
import * as mutations from "../graphql/mutations";



function QuestionShowDoneFamily(props) {





    const { answerdata } = props;



    if (answerdata.items.length <= 0) {
        return (


            <Badge variant="primary">
                Ready to Answer
            </Badge>

        )

    }else {


        return (


            <Badge variant="success">
                Done
            </Badge>


        )
    }
}


export default QuestionShowDoneFamily
