import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {API, Auth} from "aws-amplify";
import { getOwner} from './graphql/queries';
import { useHistory } from "react-router-dom";
import * as mutations from './graphql/mutations';
import {createHost, createQuestion} from "./graphql/mutations";
import "./Style.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";

import Container  from 'react-bootstrap/Container';


function AddAnotherKid() {
    // Declare a new state variable, which we'll call "count"
    const [userEmail, setuserEmail] = useState("who knows");

    const [cogemail, setCogemail] = useState("who knows");
    const [test, setTest] = useState(["Loading.."]);
    const  [id, setId] = useState(["Loading.."]);
    const [indexes, setIndexes] = React.useState([]);
    const [disabled, setDisabled] = useState(false)
    const [counter, setCounter] = React.useState(0);
    const [userName, setUserName] = useState("test")


    const history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm();



    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();
            let getId = await Auth.currentUserInfo();
            setCogemail(user.attributes.email);
            setUserName(user.attributes.name);
            setTest(user.username);
            setId(getId.id);


        })();

    }, [])


    const onSubmit = data => {







        async function magicMoment(){

            const data = {
                body: {
                    owner: test,
                    email: cogemail,

                }
            };

            //   console.log(data);
            const apiData = await API.post('magicmoment', '/host', data);
            console.log({ apiData });



        }

        async function GO(){

            let user = await Auth.currentAuthenticatedUser();







            const contactData1 = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })



            const kidDetails = {
                //  id: contactData1.data.getOwner.items[0].id,
                name: data.FirstName,
                storage: id + "/" + data.FirstName,
                owner: test,
            };



            const update = await API.graphql({ query: mutations.createSubscriber, variables: {input: kidDetails}})
            console.log("create subscriber", update)








        }


        GO();
        magicMoment();




        async function goHome() {
            const user = await Auth.currentAuthenticatedUser();
            let done = Auth.updateUserAttributes(user, {
                'custom:setup': "yes" ,

            });
            console.log("done:", done);

            history.push("/settings")

        }

        goHome();




    };

    function jumpMsg(){
        history.push("/settings")
    }
    const xxx = data => {
        console.log(data.FirstName);
        console.log(errors);

        return( jumpMsg())
    }


    /*    useEffect(() => {
            //useEffect function must return a cleanup function or nothing
            (async () => {
                let user = await Auth.currentAuthenticatedUser();
            //    let  test = await user.attributes["custom:kids"]

            //    console.log("eff",user.attributes)
            //    console.log("eff",test)
           //     let a = parseInt(test)
           //     setKids1(a)
              //  setuserEmail(user.attributes);
            })();

        }, [])*/


    return (
        <Container>
            <Row className="justify-content-md-center">
                <Card style={{ width: '30rem' }}>
                    <Card.Header>Please add your child's first name. Save when done.</Card.Header>
                    <Card.Body>

                        <Card.Text>







                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type="text" placeholder="FirstName" {...register("FirstName", {required: true, maxLength: 80})} />


                                <input type="submit" />
                            </form>








                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}
export default AddAnotherKid ;




