import React, {useEffect, useState} from 'react';

import Button from 'react-bootstrap/Button';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner} from "./graphql/queries";
import {getOwnerSub} from "./graphql/queries";
import Image  from 'react-bootstrap/Image';

import Modal from 'react-bootstrap/Modal';

import Navvy from "./components/nav"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory from 'react-bootstrap-table2-paginator';


var simpleParser = require('mailparser').simpleParser;


function Messages() {

    const [subs, setSubs] = useState(["Loading.."]);
    const [subject, setSubjet] = useState("Click Email Below");
    const [body, setBody] = useState("");
    const [image, setImage] = useState(["test"]);
    const [from, setFrom] = useState("");
    const [files, setFiles] = useState(["Loading.."]);

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);



    const columns = [ {
        dataField: 'receiver',
        text: 'Name',
        sort: true
    },
        {
            dataField: `subject`,
            text: "Subject",
            sort: true
        },
        {
            dataField: `dateSent`,
            text: "Date",
            sort: true
        }];




    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();
            let ding = await Auth.currentCredentials();
            let test = await Auth.currentUserInfo();

        //        console.log("user", user)
       //         console.log("testAuth", test)
      //          console.log("ding", ding)

            loadlists3();



            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            const sub1 = await API.graphql({
                query:   getOwnerSub,
                variables: {

                    owner : user.username

                }
            })




            setSubs(sub1.data.getOwnerSub.items)




        })();

    }, [])



    async function loadlists3(){





        var test = await  Storage.list('', { level: 'private' })

      //  console.log("s3", test)

        var yellow = test.map(message => {
            const parts = message.key.split('/');
            const receiver = parts[0];
            const sender = parts.length === 4 ? parts[1] : null;
            const dateSent = parts.length === 4 ? parts[2] : null;
            const  subject = parts.slice(-1);
            return {
                ...message,
                receiver,
                sender,
                dateSent,
                subject
            }
        })

        setFiles(yellow);







    }





    function refresh(){

        window.location.assign("https://home.memhold.com");
    }


    const rowEvents = {
        onClick: (e, row, rowIndex) => {

            Storage.get(row.key, {level: 'private', download: true})
                .then((data) => {
                    var reader = new FileReader();
                    reader.onload = function() {

                        simpleParser(reader.result, (err, parsed) => {
                            if (err) {
                                console.log(err, err.stack);

                            } else {
                                //    console.log("date:", parsed.date);

                              //  console.log("TAYLOR LOOKS:", parsed);

                                if (parsed.attachments === undefined || parsed.attachments.length == 0) {
                                    setBody(parsed.text)
                                    setSubjet(parsed.subject)
                                    setFrom(parsed.from.value[0].name)
                                    setModalShow2(true)


                                } else {


                                    function bufferToBase64(buf) {
                                        var binstr = Array.prototype.map.call(buf, function (ch) {
                                            return String.fromCharCode(ch);
                                        }).join('');
                                        return btoa(binstr);
                                    }


                                   const doubled = parsed.attachments.map(n =>  new Uint8Array(n.content));
                                    const endit = doubled.map(n => bufferToBase64(n))

                                   // console.log("endit", endit)

                                    //console.log("FRIDAY LOOK", endit)

                                //    var data = new Uint8Array(parsed.attachments[0].content);

                                 //   var base64 = bufferToBase64(data);

                                    // console.log("TG LOOK:",parsed.attachments[0].content );
                                    setBody(parsed.text)
                                    setImage(endit)
                                    setSubjet(parsed.subject)
                                    setFrom(parsed.from.value[0].name)
                                    setModalShow(true)
                                    console.log("image", image);
                                    //      console.log("from:", parsed.from.text);
                                    //      console.log("attachments:", parsed.attachments);

                                }
                            }

                        });

                    }
                    reader.readAsText(data.Body);
                })
                .catch((err) => {
                    console.log("error fetching image")
                })




        }

    }

function test2(){
 setModalShow(false)
    setModalShow2(false)
 //   setImage([""])
}


    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        From: {from}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{subject}</h4>
                    <p>
                        {body}
                    </p>

                    <p>
                        {image.map((person, index) => (
                            <Image key={index} src={"data:image/png;base64," + person } fluid  />
                        ))}
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={test2}>Close</Button>




                </Modal.Footer>
            </Modal>
        );
    }

    function MyVerticallyCenteredModal2(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        From: {from}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{subject}</h4>
                    <p>
                        {body}
                    </p>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={test2}>Close</Button>


                </Modal.Footer>
            </Modal>
        );
    }



    return (

        <div>

        <Navvy />



            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}/>
            <MyVerticallyCenteredModal2
                show={modalShow2}

                onHide={() => setModalShow2(false)}/>


            <BootstrapTable bootstrap4 striped bordered hover size="sm"    keyField='id' data={ files } columns={ columns } rowEvents={ rowEvents } pagination={ paginationFactory() } />

        </div>
    );
}
export default Messages;



