import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner, getOwnerSub} from "./graphql/queries";
import Navvy from "./components/nav"
import {Link, useHistory} from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup"


function Will() {
    const history = useHistory();

    const [subs, setSubs] = useState(["Loading.."]);
    const [useremail, setUseremail] = useState("loading.");
    const [username, setUsername] = useState("loading.");


    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();

            let userInfoget = await Auth.currentUserInfo();
            setUseremail(userInfoget.attributes.email)
            setUsername(userInfoget.username)






            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            const sub1 = await API.graphql({
                query:   getOwnerSub,
                variables: {

                    owner : user.username

                }
            })




            setSubs(sub1.data.getOwnerSub.items)




        })();

    }, [])





    return (


        <div >
            <Navvy />
            <p> </p>
            <Alert variant="success">
                <Alert.Heading>Memhold.com Will Notification </Alert.Heading>
                <p>
                  Please print, sign and place a copy of this document in your will.
                </p>
                <hr />
                <p className="mb-0">

                </p>
            </Alert>

           User Email: {useremail}   <p> </p>
            Username: {username}


            <ListGroup>
                <ListGroup.Item><p>Subscribers:</p> { subs.map((item, index) => (<ListGroup.Item key={index}>Name: {item.name} <p>Email: {item.id}@protect.memhold.com</p>

                </ListGroup.Item>)) }</ListGroup.Item>
            </ListGroup>

            <p> </p>
            <p> </p>
            To whom it may concern: Please email onpassing@memhold.com

            <p> </p><p> </p>
            Sign & Date:
            <p> </p><p> </p>

            Notes:




        </div>


    );
}
export default Will ;


