import React, {useEffect, useState} from 'react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import FamilyQA from './familyqa'
import {API, Auth } from 'aws-amplify';
import { getOwnerContact} from "./graphql/queries";
import "./Style.css";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Navvy from "./components/nav"
import {AmplifySignOut} from "@aws-amplify/ui-react";

import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Addfamily from './components/addfamily';


function Familypicker() {


    const [contacts, setContacts] = useState(["Loading.."]);
    const [familyMember, setFamilyMember] = useState("load");



    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();

            let filter = {
                familymember: {
                    eq: true
                }}



            const sub1 = await API.graphql({
                query:   getOwnerContact,
                variables: {

                    owner : user.username,
                    filter: filter,

                }
            })





            setContacts(sub1.data.getOwnerContact.items)
            console.log("sub",sub1)





        })();

    }, [])



function test(name){
        setFamilyMember("fuck")
    console.log("DMX", name)

}



    if (familyMember == "load"){


        return (
            <div>
                <Navvy />


                <Container fluid>

                    <Row className="justify-content-center">

                        <Col>



                            <Container>
                                <p> </p>
                                <Row>

                                    {contacts.map((item, index) => (

                                        <a style={{ cursor: 'pointer' }} onClick={() =>test(item.name)}>
                                            <Card
                                                bg={"primary"}
                                                text={"white"}
                                                style={{ width: '18rem' }}
                                                className="mb-2"

                                            >

                                                <Card.Body>
                                                    <h1 style={{textAlign: "center"}}>{item.name}</h1>
                                                    <Card.Text>

                                                    </Card.Text>

                                                </Card.Body>
                                            </Card>
                                        </a>



                                    ))}
                                </Row>
                            </Container>





                        </Col>

                    </Row>
                </Container>

            </div>
        );



    } else {
        return (
          <FamilyQA
         familyMember={familyMember} />
        );
    }













}
export default Familypicker ;


