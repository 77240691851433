import React, {useEffect, useState} from 'react';
import Message from "./messages.js"
import {Link} from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { AmplifySignOut} from "@aws-amplify/ui-react";
import Button from 'react-bootstrap/Button';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner, getOwnerContact, listAnswers} from "./graphql/queries";
import {getOwnerSub} from "./graphql/queries";

import Row  from 'react-bootstrap/Row';

import Card from 'react-bootstrap/Card';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Navvy from "./components/nav"
import { useHistory } from "react-router-dom";


function LifeIsGood() {

    const [subs, setSubs] = useState(["Loading.."]);
    const [msg, setMsg] = useState("Loading..");
    const [questions, setQuestions] = useState("Loading..");

    const history = useHistory();
    const clipboard = useClipboard({
        copiedTimeout: 600, // timeout duration in milliseconds
    });



function jumpMsg(){
    history.push("/messages")
}




    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();

            let test = await Auth.currentUserInfo();


            var getMsg = await  Storage.list('', { level: 'private' })
          //  console.log("msg",getMsg)
                setMsg(getMsg.length)




            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            const sub1 = await API.graphql({
                query:   getOwnerSub,
                variables: {

                    owner : user.username

                }
            })




            setSubs(sub1.data.getOwnerSub.items)




            const sub3 = await API.graphql({
                query: listAnswers,
                variables: {

                    owner : user.username

                }
            })





          // console.log("sub",sub3)
                setQuestions(sub3.data.listAnswers.items.length)




        })();



    }, [])
















    return (

        <div>

            <Navvy />
<p> </p>

            <Row className="justify-content-center">
            <a style={{ cursor: 'pointer' }} onClick={jumpMsg}>
            <Card
                bg={"primary"}
                text={"white"}
                style={{ width: '18rem' }}
                className="mb-2"
            >
                <Card.Header><h4 style={{textAlign: "center"}}> Memories Sent </h4 ></Card.Header>
                <Card.Body>
                    <h1 style={{textAlign: "center"}}>{msg}</h1>
                    <Card.Text>

                    </Card.Text>

                </Card.Body>
            </Card>
            </a>
            </Row>





    </div>
    );
}
export default LifeIsGood ;



