import React, {useEffect, useState} from 'react';
import {API, Auth} from 'aws-amplify';
import {getQuestionChapter, listQuestionprivates} from "./graphql/queries";
import {Link, useHistory} from "react-router-dom";

import Navvy from "./components/nav"
import Chapters from "./components/chapters"
import ShowQuestonsFamily from "./components/questionShowFamily"
import ShowMyQuestionsFamily from "./components/showmyquestionsFamily"
import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import {AmplifySignOut} from "@aws-amplify/ui-react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as mutations from "./graphql/mutations";




function AutoBio(props) {

    const [test, setTest] = useState(["Loading.."]);
    const [id, setId] = useState(["Loading.."]);
    const [questions, setQuestions] = useState([{answers:{items:[{response: "bug"}]}}]);
    const [myquestions, setMyquestions] = useState([{answers:{items:[{response: "bug"}]}}]);
    const [answers, setAnswers] = useState([{answers:{items:["test"]}}]);
    const [load, setLoad] = useState("load");


    const [currentChapter, setCurrentChapter] = useState("loading");
    const chapters = ["Childhood", "High school", "After High school", "Career","Family","Love", "General"];

    const { familyMember } = props;

    const [show, setShow] = useState(false);
    const [value, setValue] = useState(),
        onInput = ({target:{value}}) => setValue(value),
        onFormSubmit = async (e,id) => {
            e.preventDefault()
            setValue()


            const data = {

                question: value,
                chapter: "ding",
                status: "ready",
            };



            const update = await API.graphql({ query: mutations.createQuestionprivate, variables: {input: data}})
            console.log("updated response", update)
            handleClose();







        }


    const handleClose = () => setShow(false);

    async function handleShow(){


        setShow(true)


    }


    async function handleChange(newValue) {


        setCurrentChapter(newValue);

        console.log("chapter", newValue)

        if (newValue == "myquestions"){
            const mystuff = await API.graphql({
                query: listQuestionprivates,
                variables: {



                }
            })

            setMyquestions(mystuff.data.listQuestionprivates.items)



        } else {






            const quest = await API.graphql({
                query: getQuestionChapter,
                variables: {

                    chapter: newValue,


                }
            })



            setQuestions(quest.data.getQuestionChapter.items)


            console.log("taylor look",quest.data.getQuestionChapter.items )


        }}









/////////////////////////////////////////////////////


    if (currentChapter == "loading"){

        return (
            <div>
                <Navvy />
                <p> </p>
                <p> </p>
                <Container fluid>
                    <Row>
                        <Col sm={3}>

                            <Chapters
                                currentChapter={currentChapter}
                                onChange={handleChange}/>

                        </Col>

                        <Col md="auto">



                        </Col>
                        <Col>




                        </Col>
                    </Row>
                </Container>


            </div>
        )
    }



    else if (currentChapter == "myquestions"){

        return (
            <div>
                <Navvy />
                <p> </p>
                <p> </p>
                <Container fluid>
                    <Row>
                        <Col sm={3}>
                            <Chapters
                                currentChapter={currentChapter}
                                onChange={handleChange}/>
                            <Button variant="success" onClick={handleShow}>Add Question</Button>

                            <Modal

                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Question and Answer</Modal.Title>
                                </Modal.Header>
                                <Modal.Body >
                                    Question:

                                    <Form onSubmit={e => onFormSubmit(e,) }>
                                        <Form.Control

                                            type="text"
                                            onChange={onInput}
                                            value={value}
                                        />

                                        <Button type="submit">
                                            Save
                                        </Button>
                                    </Form>



                                </Modal.Body>
                                <Modal.Footer>

                                </Modal.Footer>
                            </Modal>



                        </Col>

                        <Col md="auto">
                            <ShowMyQuestionsFamily
                                myquestions={myquestions}

                                onChange={handleChange}/>



                        </Col>
                        <Col>




                        </Col>
                    </Row>
                </Container>





            </div>
        )


    }else{

        return (
            <div>
                <Navvy />
                <p> </p>
                <p> </p>
                <Container fluid>
                    <Row>
                        <Col sm={3}>

                            <Chapters
                                currentChapter={currentChapter}
                                onChange={handleChange}/>

                        </Col>

                        <Col md="auto">

                            <ShowQuestonsFamily
                                questions={questions}

                                onChange={handleChange}/>



                        </Col>
                        <Col>




                        </Col>
                    </Row>
                </Container>





            </div>
        )

    }





    ;
}
export default AutoBio ;


