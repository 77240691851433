/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHost = /* GraphQL */ `
  query GetHost($id: ID!) {
    getHost(id: $id) {
      id
      contacts
      owner
      email
      questions {
        nextToken
      }
      questionsprivate {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHosts = /* GraphQL */ `
  query ListHosts(
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      name
      email
      storage
      owner
      hostemail
      createdAt
      updatedAt
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        storage
        owner
        hostemail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($id: ID!) {
    getContacts(id: $id) {
      id
      name
      email
      address
      phone
      notes
      owner
      familymember
      createdAt
      updatedAt
    }
  }
`;
export const listContactss = /* GraphQL */ `
  query ListContactss(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        address
        phone
        notes
        owner
        familymember
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      trend
      test
      answers {
        nextToken
      }
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        trend
        test
        chapter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      questionID
      response
      status
      familymember
      familyname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        response
        status
        familymember
        familyname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getQuestionprivate = /* GraphQL */ `
  query GetQuestionprivate($id: ID!) {
    getQuestionprivate(id: $id) {
      id
      question
      answer
      status
      owner
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionprivates = /* GraphQL */ `
  query ListQuestionprivates(
    $filter: ModelQuestionprivateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionprivates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        answer
        status
        owner
        chapter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwner = /* GraphQL */ `
  query GetOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwnerEmail = /* GraphQL */ `
  query GetOwnerEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwnerSub = /* GraphQL */ `
  query GetOwnerSub(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerSub(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        storage
        owner
        hostemail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubEmail = /* GraphQL */ `
  query GetSubEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        storage
        owner
        hostemail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwnerContact = /* GraphQL */ `
  query GetOwnerContact(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerContact(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        address
        phone
        notes
        owner
        familymember
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionChapter = /* GraphQL */ `
  query GetQuestionChapter(
    $chapter: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionChapter(
      chapter: $chapter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
          answers {
         items {
            id
             owner
                status
                  response
                questionID}
  }
        trend
        test
        chapter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswerbyQid = /* GraphQL */ `
  query GetAnswerbyQid(
    $questionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAnswerbyQID(
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionID
        response
        status
        familymember
        familyname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
