import React, {useEffect, useState} from 'react';
import {API, Auth} from 'aws-amplify';
import {getOwner} from "./graphql/queries";
import "./Style.css";
import {Link, useHistory} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {AmplifySignOut} from "@aws-amplify/ui-react";
import * as mutations from "./graphql/mutations";
import Navvy from "./components/nav"







function Contactus() {
    const history = useHistory();
    const [email, setEmail] = useState("dunno");
    const [test, setTest] = useState([{firstName:"loading"}]);
    const [contacts, setContacts] = useState([{"firstName":"load","lastName":"load"},{"firstName":"load","lastName":"load"}]);


    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();

            let test = await Auth.currentUserInfo();





            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })



            //  console.log("TAYLOR", JSON.parse(contactData.data.getOwner.items[0].contacts))
            //setContacts(JSON.parse(contactData.data.getOwner.items[0].contacts))



        })();

    }, [])

    console.log("contacts:", contacts)

    const onSubmit = data => {
        console.log(data);

        async function GO(){

            let user = await Auth.currentAuthenticatedUser();
            let test = await Auth.currentUserInfo();
            setTest(test.id);


            const contactData1 = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            // console.log('LOOOK LOOOK: ', JSON.stringify(contactData1.data.getOwner.items[0].id));
            // console.log('LOOK LOOK:', data.friends)



            const todoDetails = {
                id: contactData1.data.getOwner.items[0].id,
                contacts:JSON.stringify(data.friends),
            };

            const updateHost = await API.graphql({ query: mutations.updateHost, variables: {input: todoDetails}});





        }









        GO();

        //  history.push("/contacts");





    };


    async function postData() {
        const apiName = 'contactus'; // replace this with your api name.
        const path = '/message'; //replace this with the path you have configured on your API
        const myInit = {
            body: {"test": "taylor.c.graham@gmail.com"}, // replace this with attributes you need
            headers: {
            }, // OPTIONAL
        };

        API
            .post(apiName, path, myInit)
            .then(response => {
                console.log(JSON.stringify(response))
            })
            .catch(error => {
                console.log(error.response);
            });

    }



    async function addContact() {
        const data = {
            body: {
                name: formState.name,
                email: formState.email,
                message: formState.message
            }
        };

        console.log(data);
        const apiData = await API.post('contactus', '/message', data);
        console.log({ apiData });
        alert('Mail sent');
    }

    const formState = { name: '', email: '', message: '' };

    function updateFormState(key, value) {
        formState[key] = value;
    }

    return (
        <div>
           <Navvy />



            <div>

                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control placeholder="Name" onChange={e => updateFormState('name', e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control placeholder="Email" onChange={e => updateFormState('email', e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control   as="textarea" placeholder="Message" onChange={e => updateFormState('message', e.target.value)} />
                    </Form.Group>
                    <Button onClick={addContact}>Send a message</Button>
                </Form>
            </div>


        </div>
    );
}
export default Contactus ;


