import React, {useEffect, useState} from 'react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';

import {API, Auth } from 'aws-amplify';
import { getOwnerContact} from "./graphql/queries";
import "./Style.css";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Navvy from "./components/nav"
import {AmplifySignOut} from "@aws-amplify/ui-react";

import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Addfamily from './components/addfamily';


function Familysettings() {


    const [contacts, setContacts] = useState(["Loading.."]);




    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();


            let filter = {
                familymember: {
                    eq: true
                }}


            const sub1 = await API.graphql({
                query:   getOwnerContact,
                variables: {

                    owner : user.username,
                    filter: filter,

                }
            })




            setContacts(sub1.data.getOwnerContact.items)
            console.log("sub",sub1)





        })();

    }, [])
















    return (
        <div>
            <Navvy />


            <Container fluid>

                <Row>
                    <Col >
                        <Card>


                            <Addfamily />

                        </Card>

                    </Col>
                    <Col>

                        <ListGroup>
                            <ListGroup.Item> { contacts.map((item, index) => (<ListGroup.Item key={index}>Name: {item.name}<p>Email: {item.email}</p>
                            </ListGroup.Item>)) }</ListGroup.Item>
                        </ListGroup>


                    </Col>

                </Row>
            </Container>

        </div>
    );
}
export default Familysettings ;


