import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner, getOwnerSub} from "./graphql/queries";
import "./Style.css";
import {Link, useHistory} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";









function S3setup() {
    const history = useHistory();













    async function makes3() {
        let user = await Auth.currentAuthenticatedUser();


        const sub1 = await API.graphql({
            query:   getOwnerSub,
            variables: {

                owner : user.username

            }
        })


        console.log('BREAK: ', sub1.data.getOwnerSub.items);




        let chess = sub1.data.getOwnerSub.items
        chess.forEach(myFunction);

        async function myFunction(item) {

            console.log("hmmmm", item.name)


                Storage.put(`${item.name}/startdate.txt`, new Date().toLocaleString(), {
                    level: 'private',
                    contentType: 'text/plain'
                })
                    .then (result => console.log(result))
                    .catch(err => console.log(err));















        }








    }






    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        }}>

            <Navbar collapseOnSelect expand="lg"  bg="primary" variant="dark">
                <Navbar.Brand  as={Link}to="/home" >Memhold</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/s3setup" >Account</Nav.Link>
                        <Nav.Link href="#pricing">Contacts</Nav.Link>

                    </Nav>

                </Navbar.Collapse>
            </Navbar>

            <center>
            <button type="submit" >Click here 1st</button>{' '}

            <button type="submit" >Click here 2nd</button>

            </center>
        </div>
    );
}
export default S3setup ;


