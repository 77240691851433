import React, {useEffect, useState} from 'react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';
import {API, Auth, Storage} from 'aws-amplify';
import {getOwner, getOwnerContact, getOwnerSub} from "./graphql/queries";
import "./Style.css";
import {Link, useHistory} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {AmplifySignOut} from "@aws-amplify/ui-react";
import * as mutations from "./graphql/mutations";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Addcontacts from './components/addcontacts';
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";

function AddContactStart() {

    const [test, setTest] = useState(["Loading.."]);
    const [contacts, setContacts] = useState(["Loading.."]);




    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();





            const sub1 = await API.graphql({
                query:   getOwnerContact,
                variables: {

                    owner : user.username

                }
            })




            setContacts(sub1.data.getOwnerContact.items)
            console.log("sub",sub1)





        })();

    }, [])
















    return (
        <div>


            <Container>

                <Card>
                    <Card.Header>Trusted Contacts</Card.Header>
                    <Card.Body>
                        <Card.Title>Friends, Family, Trusted people in your life</Card.Title>
                        <Card.Text>
                            As you know, part of this service is getting your data to your loved ones should something happen to you. <p></p>
                            Please add a few trusted people we should reach out to or who might reach out to us on your behalf.
                        </Card.Text>
                        <Addcontacts />
                    </Card.Body>
                </Card>




            </Container>

        </div>
    );
}
export default AddContactStart ;


