import React, {useEffect, useState} from 'react';


import Button from 'react-bootstrap/Button';


import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import {API, Auth} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import {useForm} from "react-hook-form";


function Addcontacts() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [indexes, setIndexes] = React.useState([]);
    const [test, setTest] = useState(["Loading.."]);
    const [counter, setCounter] = React.useState(0);
    const [contacts, setContacts] = useState(["Loading.."]);



    const { register, handleSubmit } = useForm();



    const onSubmit = data => {
       setShow(false);
        console.log(data);
        async function GO(){
            let user = await Auth.currentAuthenticatedUser();



            console.log("user",user.username)






            var fruits = data.friends

            fruits.forEach(myFunction);

            async function myFunction(item) {

                const contctDetails = {
                    //  id: contactData1.data.getOwner.items[0].id,
                    name: item.firstName,
                    email: item.email,
                    phone: item.phone,
                    address: item.address,
                    notes: item.notes,
                    owner: user.username,
                    familymember: false,
                };



                const update = await API.graphql({ query: mutations.createContacts, variables: {input: contctDetails}})
                console.log("create Contact", update)


            }





        }
        GO();
        window.location.assign("https://home.memhold.com");


    };

    const addFriend = () => {
        setIndexes(prevIndexes => [...prevIndexes, counter]);
        setCounter(prevCounter => prevCounter + 1);
    };

    const removeFriend = index => () => {
        setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
        setCounter(prevCounter => prevCounter - 1);
    };

    const clearFriends = () => {
        setIndexes([]);
    };



    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Add Trusted Contacts
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Trusted Contacts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {indexes.map(index => {
                            const fieldName = `friends[${index}]`;
                            return (
                                <fieldset name={fieldName} key={fieldName}>
                                    <label>
                                        Name:
                                        <input
                                            type="text"
                                            name={`${fieldName}.firstName`}
                                            ref={register}
                                        />
                                    </label>
                                    <label>
                                        Phone:
                                        <input
                                            type="text"
                                            name={`${fieldName}.phone`}
                                            ref={register}
                                        />
                                    </label>
                                    <label>
                                        email:
                                        <input
                                            type="text"
                                            name={`${fieldName}.email`}
                                            ref={register}
                                        />
                                    </label>
                                    <label>
                                        address:
                                        <input
                                            type="text"
                                            name={`${fieldName}.address`}
                                            ref={register}
                                        />
                                    </label>

                                    <label>
                                        notes:
                                        <input
                                            type="text"
                                            name={`${fieldName}.notes`}
                                            ref={register}
                                        />
                                    </label>

                                </fieldset>
                            );
                        })}

                        <Button variant="primary"  type="button" onClick={addFriend}>
                            Add Another Trusted Contact
                        </Button>
                            <p> </p>
                        <Button variant="success"  type="submit"  > Save </Button>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Addcontacts

