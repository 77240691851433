import React, {useEffect, useState} from 'react';
import {Auth} from "aws-amplify";


import LifeIsGood from "./lifeisgood";
import Kids from "./kids";






function Home() {
    // Declare a new state variable, which we'll call "count"
    const [userEmail, setuserEmail] = useState("who knows");
    const [sub, setSub] = useState("who knows");
    const [setup, setSetup] = useState("who knows");








    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();
         //   console.log(user)
            setuserEmail(user.attributes["custom:kids"]);
            setSetup(user.attributes["custom:setup"]);
            setSub(user.username)
          //  NewUser(user.attributes.name)




        })();






    }, )






        if (setup == "yes" ) {

        return <LifeIsGood/>
    } return ( <Kids/> );



}
export default Home

