
import React, { useState, useEffect  } from 'react';
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FamilyQA from "./familypicker"
import Messages from "./messages"
import Settings from "./settings"
import Autobiography from "./autobiography"
import Home from "./home"
import Familysettings from "./familysettings"
import Kids from './kids'
import Email from "./email"
import Will from "./will"
import AddAnotherKid from "./AddAnotherKid";
import S3setup from './s3setup'
import LifeIsGood from './lifeisgood'
import Contactus from "./contactus";
import Allmem from "./allmem";
import Profile from "./will"
import {
    BrowserRouter as Router,
    Switch,
    Route,

} from "react-router-dom";
import { withAuthenticator } from '@aws-amplify/ui-react';

import AddContactStart from "./addContactStart";

import awsconfig from './aws-exports'

Amplify.configure(awsconfig)




function UserGreeting(props) {
    return <h1>Welcome back!</h1>;
}

function GuestGreeting(props) {
    return <h1>Please sign up.</h1>;
}

function GetUserInfo() {
    // Declare a new state variable, which we'll call "count"
    const [count, setCount] = useState("who knows");



    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
            let user = await Auth.currentAuthenticatedUser();
            setCount(user.attributes.email);
        })();//IIFE

    }, [])





}
function Home2() {
    return <home />;
}
function Emai() {
    return <Email />;
}
function Familyqa1() {
    return <FamilyQA />;
}

function Will1() {
    return <Will />;
}

function AddAnotherKid1() {
    return <AddAnotherKid />;
}
function Familysettings1() {
    return <Familysettings />;
}


function Kidss() {
    return <Kids />
}

function autobiography1() {
    return <Autobiography />
}


function AddContactStart1() {
    return <AddContactStart />
}

function LifeIsGood1() {
    return <LifeIsGood />
}
function S3setup1() {
    return <S3setup />
}
function Allmem1() {
    return <Allmem />
}
function Messages1() {
    return <Messages />
}
function Settings1() {
    return <Settings />
}

//
function App() {
    return (


        <Router>
            <div>
                <Switch>

                    <Route path="/kids">
                        <Kids />
                    </Route>

                    <Route path="/addcontactstart">
                        <AddContactStart />
                    </Route>
                    <Route path="/add">
                        <AddAnotherKid />
                    </Route>

                    <Route path="/familyqa">
                        <FamilyQA />
                    </Route>
                    <Route path="/allmem">
                        <Allmem />
                    </Route>
                    <Route path="/email">
                        <Email />
                    </Route>

                    <Route path="/messages">
                        <Messages />
                    </Route>
                    <Route path="/autobiography">
                        <Autobiography />
                    </Route>
                    <Route path="/life">
                        <Home />
                    </Route>
                    <Route path="/familysettings">
                        <Familysettings />
                    </Route>
                    <Route path="/s3setup">
                        <S3setup1 />
                    </Route>
                    <Route path="/settings">
                        <Settings />
                    </Route>
                    <Route path="/will">
                        <Profile />
                    </Route>
                    <Route path="/profile">
                        <Profile />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>
        </Router>

    );
}

export default withAuthenticator(App);
