
import {getOwnerSub} from "./graphql/queries";
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {API, Auth, Storage} from 'aws-amplify';



import * as mutations from "./graphql/mutations";
import "./Style.css";

function Email() {



    useEffect(() => {
        //useEffect unction must return a cleanup function or nothing
        (async () => {



        })();

    }, [])











   async function makeEmail(){

        let user = await Auth.currentAuthenticatedUser();


        const sub1 = await API.graphql({
            query:   getOwnerSub,
            variables: {

                owner : user.username

            }
        })


        console.log('BREAK: ', sub1.data.getOwnerSub.items);




        let chess = sub1.data.getOwnerSub.items
       chess.forEach(myFunction);

       async function myFunction(item) {

           console.log("hmmmm", item.id)

           const subDetails = {
               //  id: contactData1.data.getOwner.items[0].id,
               id : item.id,
               email: item.id + '@protect.memhold.com'
           };



           const update = await API.graphql({ query: mutations.updateSubscriber, variables: {input: subDetails}})




       }
    }





    return (
        <div>
            <button onClick={makeEmail}>Make Email</button>




        </div>
    );
}
export default Email ;



/////////////////////////////////////////////////////

