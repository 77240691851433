import React, {useEffect, useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";



function Chapters(props) {

    function handleChange(event) {
        // Here, we invoke the callback with the new value
        console.log(event)
        props.onChange(event)

    }




    const { test } = props;

    const {currentChapter} = props

    return (

        <ListGroup>
            <ListGroup.Item value="earlychildhood"  action onClick={e => handleChange(e.target.value)} >
                Childhood
            </ListGroup.Item>
            <ListGroup.Item  value="highschool"  action onClick={e => handleChange(e.target.value)}>
                High school
            </ListGroup.Item >
            <ListGroup.Item value="afterhighschool" action onClick={e => handleChange(e.target.value)}>
                After High school
            </ListGroup.Item>
            <ListGroup.Item value="career" action onClick={e => handleChange(e.target.value)}>
                Career
            </ListGroup.Item>
            <ListGroup.Item value="family" action onClick={e => handleChange(e.target.value)}>
                Family
            </ListGroup.Item>
            <ListGroup.Item value="love" action onClick={e => handleChange(e.target.value)} >
                Love
            </ListGroup.Item>
            <ListGroup.Item value="health" action onClick={e => handleChange(e.target.value)} >
                Health
            </ListGroup.Item>
            <ListGroup.Item value="religion" action onClick={e => handleChange(e.target.value)} >
                Religion
            </ListGroup.Item>
            <ListGroup.Item value="travel" action onClick={e => handleChange(e.target.value)} >
                Travel
            </ListGroup.Item>
            <ListGroup.Item value="general" action onClick={e => handleChange(e.target.value)} >
                General
            </ListGroup.Item>
            <ListGroup.Item value="myquestions" action onClick={e => handleChange(e.target.value)} >
                My Questions
            </ListGroup.Item>


        </ListGroup>




    )









}

export default Chapters
