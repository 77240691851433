import React, {useEffect, useState} from 'react';


import Button from 'react-bootstrap/Button';


import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import {API, Auth} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import {useForm} from "react-hook-form";
import Navbar from "react-bootstrap/Navbar";
import {Link} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {AmplifySignOut} from "@aws-amplify/ui-react";
import Nav from "react-bootstrap/Nav";

function Navvy() {





    return (

        <Navbar collapseOnSelect expand="lg"  bg="primary" variant="dark">
            <Navbar.Brand as={Link}to="/">Memhold</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/messages">Daily Memories</Nav.Link>



                    <Nav.Link as={Link} to="/settings">Email Settings</Nav.Link>

                    <Nav.Link as={Link} to="/allmem">Download All Memories</Nav.Link>


                </Nav>
                <Form inline>
                    <AmplifySignOut ></AmplifySignOut>
                </Form>
            </Navbar.Collapse>
        </Navbar>

    );
}

export default Navvy

