/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHost = /* GraphQL */ `
  mutation CreateHost(
    $input: CreateHostInput!
    $condition: ModelHostConditionInput
  ) {
    createHost(input: $input, condition: $condition) {
      id
      contacts
      owner
      email
      questions {
        nextToken
      }
      questionsprivate {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHost = /* GraphQL */ `
  mutation UpdateHost(
    $input: UpdateHostInput!
    $condition: ModelHostConditionInput
  ) {
    updateHost(input: $input, condition: $condition) {
      id
      contacts
      owner
      email
      questions {
        nextToken
      }
      questionsprivate {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHost = /* GraphQL */ `
  mutation DeleteHost(
    $input: DeleteHostInput!
    $condition: ModelHostConditionInput
  ) {
    deleteHost(input: $input, condition: $condition) {
      id
      contacts
      owner
      email
      questions {
        nextToken
      }
      questionsprivate {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriber = /* GraphQL */ `
  mutation CreateSubscriber(
    $input: CreateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    createSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      storage
      owner
      hostemail
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriber = /* GraphQL */ `
  mutation UpdateSubscriber(
    $input: UpdateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    updateSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      storage
      owner
      hostemail
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriber = /* GraphQL */ `
  mutation DeleteSubscriber(
    $input: DeleteSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    deleteSubscriber(input: $input, condition: $condition) {
      id
      name
      email
      storage
      owner
      hostemail
      createdAt
      updatedAt
    }
  }
`;
export const createContacts = /* GraphQL */ `
  mutation CreateContacts(
    $input: CreateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    createContacts(input: $input, condition: $condition) {
      id
      name
      email
      address
      phone
      notes
      owner
      familymember
      createdAt
      updatedAt
    }
  }
`;
export const updateContacts = /* GraphQL */ `
  mutation UpdateContacts(
    $input: UpdateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    updateContacts(input: $input, condition: $condition) {
      id
      name
      email
      address
      phone
      notes
      owner
      familymember
      createdAt
      updatedAt
    }
  }
`;
export const deleteContacts = /* GraphQL */ `
  mutation DeleteContacts(
    $input: DeleteContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    deleteContacts(input: $input, condition: $condition) {
      id
      name
      email
      address
      phone
      notes
      owner
      familymember
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      trend
      test
      answers {
        nextToken
      }
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      trend
      test
      answers {
        nextToken
      }
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      trend
      test
      answers {
        nextToken
      }
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      questionID
      response
      status
      familymember
      familyname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      questionID
      response
      status
      familymember
      familyname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      questionID
      response
      status
      familymember
      familyname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createQuestionprivate = /* GraphQL */ `
  mutation CreateQuestionprivate(
    $input: CreateQuestionprivateInput!
    $condition: ModelQuestionprivateConditionInput
  ) {
    createQuestionprivate(input: $input, condition: $condition) {
      id
      question
      answer
      status
      owner
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionprivate = /* GraphQL */ `
  mutation UpdateQuestionprivate(
    $input: UpdateQuestionprivateInput!
    $condition: ModelQuestionprivateConditionInput
  ) {
    updateQuestionprivate(input: $input, condition: $condition) {
      id
      question
      answer
      status
      owner
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionprivate = /* GraphQL */ `
  mutation DeleteQuestionprivate(
    $input: DeleteQuestionprivateInput!
    $condition: ModelQuestionprivateConditionInput
  ) {
    deleteQuestionprivate(input: $input, condition: $condition) {
      id
      question
      answer
      status
      owner
      chapter
      host {
        id
        contacts
        owner
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
