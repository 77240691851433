import React, {useEffect, useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {API} from "aws-amplify";
import AnswerStatus from "./questionShowDoneFamily"
import * as mutations from "../graphql/mutations";
import {getAnswerbyQid, getQuestion, getQuestionChapter} from "../graphql/queries";



function QuestionShowFamily(props) {
    const [hotid, setHotid] = useState("load");
    const [hotchapter, setHotchapter] = useState("load");
    const [hotanswer, setHotanswer] = useState([" "]);
    const [hotanswerID, setHotanswerID] = useState([" "]);
    const [hotnamq, setHotq] = useState("load");
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [value, setValue] = useState(),
        onInput = ({target:{value}}) => setValue(value),
        onFormSubmit = async (e,id) => {
            e.preventDefault()
            setValue()


            const data = {
                questionID: hotid ,
                response: value,
                status: "done",
                familyname: "TEST-FAMILY"

            };



            const update = await API.graphql({ query: mutations.createAnswer, variables: {input: data}})
            console.log("created response", update)
            handleClose();
            handleChange(hotchapter);






        }

    const [value2, setValue2] = useState(),
        onInput2 = ({target:{value}}) => setValue2(value),
        onFormSubmit2 = async (e,id) => {
            e.preventDefault()



            const data2 = {
                id: hotanswerID,
                response: value2,
                status: "done",
            };



            const update = await API.graphql({ query: mutations.updateAnswer, variables: {input: data2}})
            console.log("updated response", update)
            console.log("value2", value2)
            handleClose2();
            handleChange(hotchapter);






        }


    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);

    async function handleShow(question,id,chapter,answer){

        setHotid(id);
        setHotq(question)
        setHotchapter(chapter)


        const quest = await API.graphql({
            query: getAnswerbyQid,
            variables: {

                questionID: id

            }
        })



        if(quest.data.getAnswerbyQID.items < 1 || quest.data.getAnswerbyQID.items[0].response == undefined){

            setHotanswer("")
            setShow(true)
        } else {
            setHotanswer(quest.data.getAnswerbyQID.items[0].response)
            setHotanswerID(quest.data.getAnswerbyQID.items[0].id)
            setShow2(true)

        }




    }


    function handleChange(event) {
        // Here, we invoke the callback with the new value
        console.log(event)
        props.onChange(event)
    }





    const { questions } = props;





    return (

        <ListGroup >

            <ListGroup.Item>
                {!!questions && questions.map((item, index) => (<ListGroup.Item  key={index}>
                    Question: {item.question}  <p> </p>
                    <p> </p> <Button variant="outline-primary"  size="sm" onClick={() => handleShow(item.question,item.id, item.chapter)}>Open
                </Button> <AnswerStatus answerdata={item.answers} />



                    <Modal

                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}

                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Question and Answer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            Question: {hotnamq} <p> </p> <p> </p>

                            <Form onSubmit={e => onFormSubmit(e, item.id) }>
                                <Form.Control
                                    as="textarea"


                                    type="text"
                                    onChange={onInput}
                                    value={value}
                                />

                                <Button type="submit">
                                    Save
                                </Button>
                            </Form>



                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>


                    <Modal

                        show={show2}
                        onHide={handleClose2}
                        backdrop="static"
                        keyboard={false}


                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Question and Answer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            Question: {hotnamq} <p> </p>  Response: {hotanswer} <p> </p>

                            <Form onSubmit={e2 => onFormSubmit2(e2, item.id) }>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    onChange={onInput2}
                                    value={value2}
                                />

                                <Button type="submit">
                                    update
                                </Button>
                            </Form>



                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>


                </ListGroup.Item>)) }</ListGroup.Item>
        </ListGroup>)
}














export default QuestionShowFamily
