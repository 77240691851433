import React, {useEffect, useState} from 'react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import { useHistory } from "react-router-dom";
import {API, Auth } from 'aws-amplify';
import {getOwner, getOwnerContact, getOwnerSub} from "./graphql/queries";
import "./Style.css";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Navvy from "./components/nav"
import {AmplifySignOut} from "@aws-amplify/ui-react";
import FileSaver from "file-saver";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";

import {useClipboard} from "use-clipboard-copy";
import Button from "react-bootstrap/Button";


function Settings() {
    const [subs, setSubs] = useState(["Loading.."]);
    const history = useHistory();
    const clipboard = useClipboard({
        copiedTimeout: 600, // timeout duration in milliseconds
    });



   function Goadd(){

       history.push("/add")
   }

    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {



            let user = await Auth.currentAuthenticatedUser();

            let test = await Auth.currentUserInfo();


            console.log("user", user)
            console.log("testAuth", test)




            const contactData = await API.graphql({
                query:  getOwner,
                variables: {

                    owner : user.username

                }
            })
            const sub1 = await API.graphql({
                query:   getOwnerSub,
                variables: {

                    owner : user.username

                }
            })




            setSubs(sub1.data.getOwnerSub.items)






        })();

    }, [])

    function vcard(name, id){

        var file = new Blob(
            [
                `BEGIN:VCARD
VERSION:3.0
N:${name};;;;

EMAIL;type=INTERNET;type=pref:${id}@protect.memhold.com

END:VCARD
`
            ],
            { type: "text/vcard;charset=utf-8" }
        );
        FileSaver.saveAs(
            file,
            `${name}.vcf`,
            true
        );





    }














    return (
        <div>
            <Navvy />


            <Container fluid>
                <Container fluid="md">

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <ListGroup>
                                <ListGroup.Item><p>Children: </p> { subs.map((item, index) => (<ListGroup.Item key={index}>Name: {item.name} <p>Email: {item.id}@protect.memhold.com</p><Button variant="outline-success" onClick={() => clipboard.copy(item.id+"@protect.memhold.com")}>

                                    Copy Email </Button><Button onClick={() => vcard(item.name,item.id)} variant="outline-success">Download Contact</Button>
                                </ListGroup.Item>)) }</ListGroup.Item>

                            </ListGroup>





                        </Col>

                        <Col md="auto">
                            <Button onClick={Goadd} variant="outline-success">Add</Button>
                        </Col>
                    </Row>


                </Container>

            </Container>

        </div>
    );
}
export default Settings ;


